export const apis = {
    launchRo: 'https://api.dev.cdr.sipora.io/launchro',
    checkConnection: 'https://api.dev.cdr.sipora.io/ro/checkconnection',
    retrieveRewards: 'https://api.dev.cdr.sipora.io/ro/rewards',
    retrieveConsent: 'https://api.dev.cdr.sipora.io/user/consent',
    rewardsTotals: 'https://api.dev.cdr.sipora.io/ro/rewards/totals',
    alternatesTotals: 'https://api.dev.cdr.sipora.io/ro/alternates/totals',
    checkJob: 'https://dev_basiq_check_job.cdrproducts.workers.dev',


}