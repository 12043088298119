import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { apis } from '../../Config/apis.js';
import { headers } from '../../Config/settings.js';

// Correctly destructure onDataLoaded from props
function MissedRewards({ onDataLoaded }) {
    const [isLoading, setIsLoading] = useState(true);
    const [totalCashBack, setTotalCashBack] = useState(0); // Use a numerical initial state
    const [uniqueTotalMerchants, setUniqueTotalMerchants] = useState(0); // Assuming you might use it

    useEffect(() => {
        // Track page views
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        
        // Retrieve cdrid from localStorage
        const cdrid = localStorage.getItem('cdrid');

        const fetchData = async () => {
            try {
                const [response, altResponse] = await Promise.all([
                    fetch(apis.rewardsTotals, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({ cdrid }),
                    }),
                    fetch(apis.alternatesTotals, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({ cdrid }),
                    }),
                ]);

                if (!response.ok || !altResponse.ok) {
                    throw new Error('API call failed');
                }

                const [responseData, altResponseData] = await Promise.all([response.json(), altResponse.json()]);
                const totalRewardsValue = parseFloat(responseData.totalRewardsValue) + parseFloat(altResponseData.totalRewardsValue);

                // No need to format here if you're going to handle formatting in rendering or parent
                setTotalCashBack(totalRewardsValue); // Store the numeric value directly

                // Optionally, if onDataLoaded expects a formatted string:
                // const formattedTotalCashBack = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalRewardsValue);
                // onDataLoaded(formattedTotalCashBack); // Notify parent component, pass formatted string if needed

                // If onDataLoaded expects a number:
                onDataLoaded(totalRewardsValue); // Notify parent component
            } catch (error) {
                console.error('API call failed:', error);
                // Optionally, pass error back to parent
                // onDataLoaded(0, error); // Consider modifying onDataLoaded to accept an error
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [onDataLoaded]); // Include onDataLoaded in the dependency array

    useEffect(() => {
        // Update local storage when totalCashBack changes
        // Consider moving this to parent if you want to keep components pure
        const formattedTotalCashBack = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack);
        localStorage.setItem('totalCashBack', formattedTotalCashBack);
    }, [totalCashBack]);

    return (
        <div className="points-display">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="points2">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCashBack)}</div>
                    <div className="points-label">*Potential cashback</div>
                </>
            )}
        </div>
    );
}

export default MissedRewards;
