const apiKey = 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE'
const tenantId = 'POKITPAL-DEV'

export const headers = {
        'Content-Type': 'application/json',
        'tenantId': tenantId,
        'apiKey': apiKey
}

export const joinLink = 'https://onelink.to/gwjcm7'

export const goals = [
    "save money",
    "pay off my debt",
    "buy a house",
    "go on a dream trip"
  ];