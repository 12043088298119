import React, { useEffect } from 'react';
import './shared.css';
import RegisterEmail from './Forms/RegisterEmail';
import Logo from './Images/pokitpal_logo_white.png';
import ReactGA from 'react-ga4';


function WeCanHelp() {
    useEffect(() => {
  
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
      

    
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">Looks like we can help you crush your money goal!</h1>
    <p className="subtitle">We have helped people like you to save hundreds of dollars by claiming cashback rewards they missed out on.</p>
  </div>


  <p className="title">Want to find out what you are missing?</p>
  <RegisterEmail />
</div>

)
}

export default WeCanHelp;