import React, { useState } from 'react';
import '../../Components/shared.css';
import { apis } from '../../Config/apis.js';
import { headers } from '../../Config/settings.js';


function RegisterConsent({ cdrid }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
    
  const submitCDRID = async (cdrid) => {
  
    try {
      const response = await fetch(apis.retrieveConsent, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ cdrid: cdrid }),
      });

      const responseData = await response.json();

      if (response.ok) {
        // Construct the path and parameters for React Router navigation
        const redirectUrl = responseData.consentLink;
        window.location.href = redirectUrl;
        // Use navigate from react-router-dom for navigation, avoiding window.location.href
       
      } else {
        // Handle non-successful responses
        console.error('Error Launching Consent:', responseData.message);
        alert(responseData.message || 'Error Launching Consent - Start Again');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error Launching Consent - Start Again');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await submitCDRID(cdrid);

    
  };

  return (
    <div className="form-container">
    <form 
     className="email-form-field"
      onSubmit={handleSubmit}
      >
      <button className="submit-email-button" type="submit" disabled={isSubmitting} >
      {isSubmitting ? 'Launching Consent...' : `Connect With Your Bank`}</button>
    </form></div>
  );
}

export default RegisterConsent;
