import React, { useEffect } from 'react';
import './shared.css';
import RegisterEmail from './Forms/RegisterEmail';
import Logo from './Images/pokitpal_logo_white.png';
import ReactGA from 'react-ga4';


function LandingPage() {
    useEffect(() => {
  
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
      
      const queryParams = new URLSearchParams(window.location.search);
      const cdrid = queryParams.get('cdrid');
      const existingValue = queryParams.get('existing') || 'false'; 
    
      // Optionally set in localStorage if needed elsewhere
      localStorage.setItem('cdrid', cdrid);
      localStorage.setItem('existing', existingValue);
    
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
    <h1 className="title">Unlock Missed Rewards</h1>
    <p className="subtitle">Did you know that most people are missing out on a mountain of cashback over a 12 month period?</p>
  </div>
  <div className="points-display">
    <div className="points">$2,460</div>
    <div className="points-label">*Avg Missed Cashback</div>
  </div>

  <p className="title">Want to find out what you are missing?</p>
  <RegisterEmail />
</div>

)
}

export default LandingPage;